import styled from 'styled-components'

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 80px 0;
`

const ProductTitle = styled.h2`
	padding-bottom: 30px;

	@media (max-width: ${props => props.theme.sizes.maxMobile}) {
		max-width: 330px;
	}
`

const Title = styled.h3`
	font-size: 1.3rem;
	width: 100%;
`

const Benefits = styled.div`
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	max-width: 800px;
	margin-top: 30px;
`

const Benefit = styled.p`
	display: flex;
	align-items: center;
	min-width: 170px;
	margin-right: 50px;
	margin-bottom: 10px;
`

const Fuel = styled.div`
	display: flex;
	font-family: 'Poppins-bold';
	align-items: center;
	margin-top: 20px;
`

const FuelType = styled.p`
	font-family: 'Poppins';
	margin: 0 20px;
`

const MotorSpecifications = styled.div`
	width: 60%;
`

const MotorSpecificationsRow = styled.div`
	display: flex;

	flex-direction: column;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
	}
`

const Specification = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 0;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
		margin-bottom: 24px;
	}
`

const SpecificationTitle = styled.p`
	min-width: 250px;

	/* @media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
	} */
`

const SpecificationDetail = styled.p`
	font-family: 'Poppins-bold';
	min-width: 50%;

	/* @media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
	} */
`

const ExtraOptions = styled.div`
	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		margin-left: 100px;
		width: 40%;
	}
`

const ButtonsRow = styled.div`
	display: flex;
	padding: 20px 0;
	flex-direction: column;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		flex-direction: row;
	}
`

const BottomTextContainer = styled.div`
	display: flex;

	flex-direction: column;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
	}
`

const BottomText = styled.p``

const BottomTextNote = styled.p`
	font-style: italic;
	color: #6b6b6b;
`

const LeftContainer = styled.div`
	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		width: 50%;
	}
`

const RightContainer = styled.div`
	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		width: 50%;
		padding-left: 100px;
	}
`

const Tabs = styled.div`
	display: flex;
	padding-bottom: 50px;
`

const PrimaryTab = styled.div`
	font-family: ${props => (props.active ? 'Poppins-bold' : 'Poppins')};
	padding: 30px 80px;
	border-radius: 5px;
	background-color: ${props => (props.active ? '#b0bec5' : '#f7f7f7')};
	cursor: pointer;

	@media (max-width: ${props => props.theme.sizes.maxDesktop}) {
		padding: 30px 50px;
	}
`
const SecondaryTab = styled.div`
	font-family: ${props => (props.active ? 'Poppins-bold' : 'Poppins')};
	padding: 30px 80px;
	border-radius: 5px;
	background-color: ${props => (props.active ? '#b0bec5' : '#f7f7f7')};
	cursor: pointer;

	@media (max-width: ${props => props.theme.sizes.maxDesktop}) {
		padding: 30px 50px;
	}
`

const LocationsWrapper = styled.div`
	max-width: 750px;
`

export {
	ProductTitle,
	Title,
	ContentWrapper,
	Benefits,
	Benefit,
	Fuel,
	FuelType,
	MotorSpecifications,
	MotorSpecificationsRow,
	Specification,
	SpecificationTitle,
	SpecificationDetail,
	ExtraOptions,
	ButtonsRow,
	BottomTextContainer,
	BottomText,
	BottomTextNote,
	RightContainer,
	LeftContainer,
	LocationsWrapper,
	Tabs,
	PrimaryTab,
	SecondaryTab
}
