import React, { useState } from 'react'
import PageLayout from '../../layouts/PageLayout'
import PerformanceTableResults from '../../components/PerformanceTableResults'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import LinkOrButton from '../../components/LinkOrButton'
import Icon from '../../components/Icon'
import { isMobileOnly } from 'react-device-detect'
import { navigate } from 'gatsby'

import { OnClickButton } from '../../components/Button/Button.styled'

// eslint-disable-next-line no-unused-vars
import { graphql } from 'gatsby'

import {
	ProductTitle,
	Title,
	ContentWrapper,
	Benefits,
	Benefit,
	Fuel,
	FuelType,
	MotorSpecifications,
	MotorSpecificationsRow,
	Specification,
	SpecificationTitle,
	SpecificationDetail,
	ExtraOptions,
	ButtonsRow,
	BottomTextContainer,
	BottomText,
	BottomTextNote,
	LeftContainer,
	RightContainer,
	Tabs,
	PrimaryTab,
	SecondaryTab,
	LocationsWrapper
} from './productPage.styled'

const ProductPageTemplate = ({ data }) => {
	const brandName = data.product.brand.name
	const modelName = data.product.model.name
	const productName = data.product.name

	const [activeSecondaryTab, setActiveSecondaryTab] = useState(false)

	const productData = {
		seo: {
			canonical: `/chiptunen/${data.product.slug}`,
			cornerstone: false,
			focuskw: `chiptunen ${brandName} ${modelName} ${productName}`,
			metaDesc: `Het chiptunen van jouw ${brandName} ${modelName} ${productName} geeft jouw auto meer pit en souplesse. Naast het extra vermogen wat jouw ${brandName} ${modelName} ${productName} krijgt kan chiptuning een aanzienlijke brandstofbesparing opleveren. Een besparing van wel 5 tot 10% is mogelijk na het chiptunen van jouw ${brandName} ${modelName} ${productName}.`,
			metaKeywords: `chiptunen ${brandName} - ECU adaptions`,
			metaRobotsNofollow: 'follow',
			metaRobotsNoindex: 'index',
			opengraphTitle: `Chiptunen ${brandName} ${modelName} ${productName}  - ECU adaptions`,
			title: `Chiptunen ${brandName} ${modelName} ${productName} - ECU adaptions`
		},
		header: {
			backgroundColor: '#000000',
			backgroundOpacity: 0.2,
			fieldGroupName: 'header',
			height: 50,
			heroChoice: 'image',
			heroText: brandName,
			image: data.brand.image,
			pxOrVh: 'vh',
			videoUrl: null
		},
		...data.product
	}

	return (
		<PageLayout pageData={productData}>
			<ContentWrapper>
				<ProductTitle>
					{brandName} {modelName} {productName}
				</ProductTitle>
				<Tabs>
					<PrimaryTab active={!activeSecondaryTab} onClick={() => setActiveSecondaryTab(false)}>
						Stage 1
					</PrimaryTab>
					<SecondaryTab active={activeSecondaryTab} onClick={() => setActiveSecondaryTab(true)}>
						Stage 1+
					</SecondaryTab>
				</Tabs>
				<PerformanceTableResults data={productData.details} />
				<Benefits>
					<Benefit>
						<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
						Testrit op de weg
					</Benefit>
					<Benefit>
						{' '}
						<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
						Motordiagnose + datalogs
					</Benefit>
					<Benefit>
						{' '}
						<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
						Stage 1 software
					</Benefit>
					<Benefit>
						{' '}
						<Icon
							width="1.3rem"
							height="1.3rem"
							color={activeSecondaryTab ? '#0685C5' : '#da2d2d'}
							margin="0 10px 0 0"
							icon={activeSecondaryTab ? faCheck : faTimes}
						/>
						Tuning certificaat
					</Benefit>
					<Benefit>
						{' '}
						<Icon
							width="1.3rem"
							height="1.3rem"
							color={activeSecondaryTab ? '#0685C5' : '#da2d2d'}
							margin="0 10px 0 0"
							icon={activeSecondaryTab ? faCheck : faTimes}
						/>
						Vermogensrun voor en na
					</Benefit>
				</Benefits>
				<Fuel>
					Brandstof <FuelType>{productData.details.fuelType}</FuelType>
				</Fuel>
				<LocationsWrapper>
					<Title>Neem contact op met een van onze locaties om een afspraak te maken</Title>
					<BottomText>
						Door samen te werken met tuning partners kunnen wij u de mogelijkheid bieden om te kiezen voor ECU adaptions
						kwaliteit software, zonder dat u af hoeft te reizen naar onze hoofdlocatie. Alle software wordt getest op
						onze 4x4 testbank van Dynostar.
					</BottomText>
					<OnClickButton margin={'0'} onClick={() => navigate('/vestigingen/')} variant="primary" padding="18px 60px">
						Bekijk beschikbare locaties
					</OnClickButton>
				</LocationsWrapper>

				<MotorSpecificationsRow>
					<MotorSpecifications>
						<Title>Motorspecificaties</Title>
						<Specification>
							<SpecificationTitle>Cilinderinhoud </SpecificationTitle>
							<SpecificationDetail>{productData.details?.cylinderCapacity ?? '-'}</SpecificationDetail>
						</Specification>
						<Specification>
							<SpecificationTitle>Compressieverhouding </SpecificationTitle>
							<SpecificationDetail>{productData.details?.compressionRatio ?? '-'}</SpecificationDetail>
						</Specification>
						<Specification>
							<SpecificationTitle>Type ECU </SpecificationTitle>
							<SpecificationDetail>{productData.details?.ecuType ?? '-'}</SpecificationDetail>
						</Specification>
						<Specification>
							<SpecificationTitle>Boring X slag </SpecificationTitle>
							<SpecificationDetail>{productData.details?.boringX ?? '-'}</SpecificationDetail>
						</Specification>
						<Specification>
							<SpecificationTitle>Motornummer </SpecificationTitle>
							<SpecificationDetail>{productData.details?.motorNumber ?? '-'}</SpecificationDetail>
						</Specification>
					</MotorSpecifications>
					<ExtraOptions>
						<Title>Aanvullende opties</Title>
						<Benefit>
							<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Foutcodes / Verwijderen van DTCs
						</Benefit>
						<Benefit>
							<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							<a href="/blog/pop-bangs-crackle-map">Pop & Bang Crackle map</a>
						</Benefit>
						<Benefit>
							<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Decat
						</Benefit>
						<Benefit>
							<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Swirl flaps
						</Benefit>
						<Benefit>
							<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Vmax
						</Benefit>
					</ExtraOptions>
				</MotorSpecificationsRow>
				<ButtonsRow>
					<OnClickButton
						onClick={() =>
							navigate('/offerte-aanvragen/', {
								state: { productData }
							})
						}
						variant="primary"
						padding="18px 60px"
					>
						Offerte aanvragen
					</OnClickButton>

					<LinkOrButton
						margin={isMobileOnly ? '10px 0 0 0' : '0 0 0 40px'}
						variant="secondary"
						padding="18px 60px"
						type={'button'}
						url="/contact"
						text="Neem contact op"
					/>
					<LinkOrButton
						margin={isMobileOnly ? '10px 0 0 0' : '0 0 0 40px'}
						variant="secondary"
						padding="18px 60px"
						type={'button'}
						url="tel:0180396341"
						text="Bel ons"
					/>
				</ButtonsRow>
				<BottomTextContainer>
					<LeftContainer>
						<Title>
							Chiptunen {brandName} {modelName} {productName}
						</Title>
						<BottomText>
							Het chiptunen van jouw {brandName} {modelName} {productName} geeft jouw auto meer pit en souplesse. Naast
							het extra vermogen wat jouw {brandName} {modelName} {productName} krijgt kan chiptuning een aanzienlijke
							brandstofbesparing opleveren. Een besparing van wel 5 tot 10% is mogelijk na het chiptunen van jouw{' '}
							{brandName} {modelName} {productName}.
						</BottomText>
						<BottomTextNote>
							Let op; harde garanties kan een chiptuner hier nooit op geven, er zijn immers veel externe factoren die
							het brandstofverbruik (positief of negatief) kunnen beïnvloeden.
						</BottomTextNote>
					</LeftContainer>
					<RightContainer>
						<Title>
							Dit zijn de voordelen van het op maat chiptunen van jouw {brandName} {modelName} {productName} door ECU
							Adaptions:
						</Title>
						<Benefit>
							<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer vermogen
						</Benefit>
						<Benefit>
							<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer trekkracht
						</Benefit>
						<Benefit>
							<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Minder brandstofverbruik
						</Benefit>
						<Benefit>
							<Icon width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer rijplezier en comfort
						</Benefit>
					</RightContainer>
				</BottomTextContainer>
			</ContentWrapper>
		</PageLayout>
	)
}

export default ProductPageTemplate

export const query = graphql`
	query ProductQuery($productId: String!, $brandId: String!) {
		brand(_id: { eq: $brandId }) {
			image {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 1920)
					}
				}
			}
		}
		product(id: { eq: $productId }) {
			id
			name
			details {
				pkBefore
				pkAfter
				nmBefore
				nmAfter
				fuelType
				cylinderCapacity
				compressionRatio
				ecuType
				boringX
				motorNumber
			}
			brand {
				name
				_id
			}
			model {
				name
				_id
			}
			generation {
				name
				_id
			}
			slug
		}
	}
`
