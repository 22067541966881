import styled from 'styled-components'

const PerformanceTable = styled.div``

const PerformanceTableRow = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0;
	justify-content: space-between;

	width: 350px;

	/* @media (min-width: ${props => props.theme.sizes.maxTablet}) {
		max-width: 75px;
	} */

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		width: 610px;
	}
`

const PerformanceResultDifference = styled.p`
	font-family: 'Poppins-bold';
	color: ${props => props.theme.colors.primary};
	font-size: 1rem;
	margin: 0;
	min-width: 60px;
	border-bottom: 4px solid ${props => props.theme.colors.primary};

	/* @media (min-width: ${props => props.theme.sizes.maxTablet}) {
		max-width: 75px;
	} */

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		font-size: 1.5rem;
		min-width: 100px;
	}
`

const PerformanceTitle = styled.p`
	font-family: 'Poppins-bold';
	margin: 0;
	width: 80px;
`

const Spacer = styled.img`
	max-width: 25px;
	position: relative;
	bottom: 3px;
	/* 
	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		max-width: 75px;
	} */

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		max-width: 75px;
	}
`

const PerformanceResult = styled.p`
	margin: 0;
`

const PerformanceDescription = styled.div`
	display: flex;
	width: 350px;
	justify-content: space-between;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		width: 570px;
	}
`

const PerformanceDescriptionTitle = styled.p`
	width: 80px;
	font-family: 'Poppins-bold';
	margin-bottom: 10px;
`
const PerformanceDescriptionResultTitle = styled.p`
	font-family: 'Poppins-bold';
	color: ${props => props.theme.colors.primary};
	margin-bottom: 10px;
`

export {
	PerformanceTable,
	PerformanceTableRow,
	PerformanceDescription,
	PerformanceDescriptionTitle,
	PerformanceDescriptionResultTitle,
	PerformanceResult,
	PerformanceTitle,
	PerformanceResultDifference,
	Spacer
}
