import React from 'react'

import greySpacerSmall from '../../../content/assets/svg/spacer-grey-small.svg'
import blueSpacerSmall from '../../../content/assets/svg/spacer-blue-small.svg'
import greySpacer from '../../../content/assets/svg/spacer-grey.svg'
import blueSpacer from '../../../content/assets/svg/spacer-blue.svg'
import { calculateDifference } from '../../helpers/calculateDifference'
import { isMobileOnly } from 'react-device-detect'

import {
	PerformanceTable,
	PerformanceTableRow,
	PerformanceResult,
	PerformanceTitle,
	PerformanceDescription,
	PerformanceDescriptionTitle,
	PerformanceDescriptionResultTitle,
	PerformanceResultDifference,
	Spacer
} from './PerformanceTableResults.styled'

const PerformanceTableResults = details => {
	return (
		<PerformanceTable>
			<PerformanceDescription>
				<PerformanceDescriptionTitle />
				<PerformanceDescriptionTitle>Origineel</PerformanceDescriptionTitle>
				<PerformanceDescriptionTitle>Stage 1</PerformanceDescriptionTitle>
				<PerformanceDescriptionResultTitle>Winst</PerformanceDescriptionResultTitle>
			</PerformanceDescription>
			<PerformanceTableRow>
				<PerformanceTitle>Vermogen</PerformanceTitle>
				<Spacer src={isMobileOnly ? greySpacerSmall : greySpacer} />
				<PerformanceResult>{details.data.pkBefore} pk</PerformanceResult>
				<Spacer src={isMobileOnly ? greySpacerSmall : greySpacer} />
				<PerformanceResult>{details.data.pkAfter} pk</PerformanceResult>
				<Spacer src={isMobileOnly ? blueSpacerSmall : blueSpacer} />
				<PerformanceResultDifference>
					+ {calculateDifference(details.data.pkBefore, details.data.pkAfter)} pk
				</PerformanceResultDifference>
			</PerformanceTableRow>
			<PerformanceTableRow>
				<PerformanceTitle>Koppel</PerformanceTitle>
				<Spacer src={isMobileOnly ? greySpacerSmall : greySpacer} />
				<PerformanceResult>{details.data.nmBefore} nm</PerformanceResult>
				<Spacer src={isMobileOnly ? greySpacerSmall : greySpacer} />
				<PerformanceResult>{details.data.nmAfter} nm</PerformanceResult>
				<Spacer src={isMobileOnly ? blueSpacerSmall : blueSpacer} />
				<PerformanceResultDifference>
					{' '}
					+ {calculateDifference(details.data.nmBefore, details.data.nmAfter)} nm
				</PerformanceResultDifference>
			</PerformanceTableRow>
		</PerformanceTable>
	)
}

export default PerformanceTableResults
